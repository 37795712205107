import { useLocation, useNavigate } from 'react-router-dom';

import './Ribbon.css';
import { Button, Link, ArrowLeftIcon } from 'evergreen-ui';

const Ribbon = ({
  text,
  showBackButton = true,
}: {
  text: string;
  showBackButton?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className='ribbon-container'>
        {showBackButton && (
          <div
            className='ribbon-back-button'
            onClick={handleBackClick}
            role='button'
            tabIndex={0}
            aria-label='Go back'
            style={{ cursor: 'pointer' }}
          >
            <ArrowLeftIcon color='white' size={20} style={{ marginLeft: 15 }} />
          </div>
        )}
        <div className='ribbon-text'>{text}</div>
      </div>
      {location.pathname !== '/' && (
        <div className='ribbon-link-container'>
          <Button onClick={() => navigate('/')} className='ribbon-link-text'>
            Home
          </Button>
        </div>
      )}
    </>
  );
};

export default Ribbon;
