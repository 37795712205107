import { Button, Card, Heading, PlusIcon } from 'evergreen-ui';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { supabase } from './utils';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from './AppContext';
import Ribbon from './Ribbon';
import useLoadAllData from './useLoadAllData';
import NewCategoryV2 from './NewCategoryV2';
import useStore from './useStore';
import CategorySummary from './CategorySummary';
import useIsAdmin from './useIsAdmin';

const Home = () => {
  // dialogs
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState<string>('All');

  const { categories, setCategories } = useContext(AppContext);
  const tourneys = useStore((state) => state.tourneys);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { user } = useParams();
  const isAdmin = useIsAdmin();

  const { loadAllData } = useLoadAllData();

  useEffect(() => {
    loadAllData();
  }, []);

  const refreshData = useCallback(() => {
    setLoading(true);
    supabase
      .from('categories')
      .select()
      .then((response) => {
        if (response.data) {
          setCategories(response.data);
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    refreshData();
  }, []);

  const currentTourney = useMemo(
    // () => tourneys[tourneys.length - 1],
    () => tourneys[0],
    [tourneys]
  );
  const sortedCategories = categories.sort((a, b) => (a.id > b.id ? -1 : 1));

  useEffect(() => {
    if (sortedCategories.length > 0) {
      setSelectedCategory(`${sortedCategories[0].id}`);
    }
  }, [sortedCategories]);

  const filteredCategories = useMemo(() => {
    if (categoryFilter === 'All') {
      return sortedCategories.filter(
        (category) => Number(category.tourneyId) === currentTourney?.id
      );
    }

    // Fix for apostrophes and case sensitivity
    const filterValue = categoryFilter.toLowerCase().replace(/\'s/g, '').trim();

    return sortedCategories.filter(
      (category) =>
        Number(category.tourneyId) === currentTourney?.id &&
        category.name.toLowerCase().replace(/\'s/g, '').includes(filterValue)
    );
  }, [sortedCategories, categoryFilter, currentTourney]);

  const filterTags = ['All', "Men's", "Women's", 'Junior', 'Challenge'];

  return (
    <>
      <div className='home-page-image-container'>
        <img src='/logo.png' height='120px' alt='Pickleball Tournament Logo' />
      </div>
      {currentTourney && (
        <>
          <Ribbon text={currentTourney.name} showBackButton={false}></Ribbon>
          <NewCategoryV2
            isShown={showCategoryDialog}
            setIsShown={setShowCategoryDialog}
            tourneyId={currentTourney.id}
          />
        </>
      )}

      <div className='tableWrapper'>
        <Heading size={600} marginTop={20} marginBottom={20}>
          Tournament Categories
        </Heading>

        <ul className='cloud' role='navigation'>
          {filterTags.map((tag) => (
            <li key={tag}>
              <Button
                className={
                  categoryFilter === tag
                    ? 'cloud-button1-active'
                    : 'cloud-button1'
                }
                onClick={() => setCategoryFilter(tag)}
              >
                {tag}
              </Button>
            </li>
          ))}
        </ul>

        {isAdmin && (
          <div className='actionRow'>
            <div>
              <Button
                appearance='primary'
                onClick={() => setShowCategoryDialog(true)}
                height={40}
                iconBefore={PlusIcon}
              >
                <b>Add a new category</b>
              </Button>
            </div>
          </div>
        )}

        {filteredCategories.length === 0 ? (
          <Card
            elevation={1}
            background='tint1'
            padding={16}
            marginTop={20}
            textAlign='center'
          >
            No categories found for this filter.{' '}
            {isAdmin &&
              categoryFilter === 'All' &&
              'Use the button above to add a new category.'}
          </Card>
        ) : (
          <div className='categories'>
            {filteredCategories.map((category) => {
              return (
                <Card
                  key={category.id}
                  elevation={2}
                  className='categoryBox'
                  onClick={() => navigate(`/category/${category.id}`)}
                >
                  <CategorySummary categoryId={category.id} />
                </Card>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
